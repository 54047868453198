import React, { useState, useEffect } from "react";
import Button from "shared/components/buttons/Button";
import { useTranslation } from "react-i18next";
import Accordion from "components/accordion";
import AlertContainer from "shared/components/AlertContainer";
import "./style.scss";
import { disableMFAAction } from "redux/actions/manageUser/remove2FAction.js";
import { fetchMFAData } from "redux/actions/manageUser/fetchMFADataAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { organisationRoles } from "constants/role";

const DisableTwoFactorAuth = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const [mfaData, setMfaData] = useState(null);
    const [isAlertVisible, setAlertVisible] = useState(false);
    const organisationId = useSelector(
        (state) => state.organisation?.membership?.organisationId
    );
    const role = useSelector(
        (state) => state.organisation?.membership?.organisation?.role
    );
    const { id: userId } = params;

    const handleButtonClick = () => {
        setAlertVisible(true);
    };

    const handleCloseAlert = () => {
        setAlertVisible(false);
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await dispatch(fetchMFAData(userId));
                if (isMounted) {
                    setMfaData(response.data.mfaData.otpEnabled);
                }
            } catch (error) {
                console.error('Error fetching MFA data:', error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [dispatch]);

    const handleConfirmDisableMFA = async () => {
        try {
            await dispatch(disableMFAAction(userId, organisationId));
            setAlertVisible(false);
            setMfaData(false);
        } catch (error) {
            console.error("Error disabling MFA:", error);
        }
    };

    const userHasValidRole = (role) => {
        return role === organisationRoles.OWNER ||
            role === organisationRoles.ADMINISTRATOR ||
            role === organisationRoles.ORGANISATION_MANAGER;
    };

    const shouldRender = mfaData && userHasValidRole(role);

    return (
        <>
            {shouldRender ? (
                <Accordion
                    checked={true}
                    index={0}
                    subtext={t("organisation.module.edit.2fa.subtext")}
                    title={t("organisation.module.edit.2fa.title")}
                >
                    <Button
                        label={t("button.2fa.disable.label")}
                        secondary
                        spacedLeft
                        onClick={handleButtonClick}
                    />
                </Accordion>
            ) : null}

            {isAlertVisible && (
                <div className="ok-choice">
                    <AlertContainer>
                        <h3>{t("organisation.module.edit.2fa.confirmation.title")}</h3>
                        <div className="spacer" />
                        <p>{t("organisation.module.edit.2fa.confirmation.subtext")}</p>
                        <div className="spacer-double" />
                        <div className="actions is-column cols">
                            <div className="col">
                                <Button
                                    label={t("button.2fa.disable.confirmation.label")}
                                    secondary
                                    fullWidth
                                    onClick={handleConfirmDisableMFA}
                                />
                            </div>
                            <div className="col">
                                <Button
                                    outlined
                                    fullWidth
                                    onClick={handleCloseAlert}
                                    label={t("button.close.label")}
                                />
                            </div>
                        </div>
                    </AlertContainer>
                </div>
            )}
        </>
    );
};

export default DisableTwoFactorAuth;
