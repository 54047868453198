import client from "graphql/client";
import { loader } from "graphql.macro";
//import {disableOTPMutation} from "graphql/mutations/mfa/index.js";
const disableOTPMutation = loader("graphql/mutations/mfa/disableOTPMutation.graphql");

export function disableMFAAction(userId, organisationId) {
    return async (dispatch) => {
        try {
            const response = await client.mutate({
                mutation: disableOTPMutation,
                variables: { userId, organisationId }
            });
            return response;
        } catch (error) {
            console.error("Error generating QR code:", error);
        }
    };
}
