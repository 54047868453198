import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import Cookies from "universal-cookie";

// Fragment Matcher (common configuration)
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [
                {
                    kind: "UNION",
                    name: "MeasureAttributeType",
                    possibleTypes: [
                        { name: "PeriodType" },
                        { name: "PhaseShiftType" }
                    ]
                },
                {
                    kind: "UNION",
                    name: "TaskableType",
                    possibleTypes: [
                        { name: "DossierType" },
                        { name: "ReportType" }
                    ]
                },
                {
                    kind: "UNION",
                    name: "AttributeType",
                    possibleTypes: [
                        { name: "AttributeAssetType" },
                        { name: "AttributeGroupType" },
                        { name: "AttributePersonType" },
                        { name: "AttributeStructureType" },
                        { name: "FileType" }
                    ]
                }
            ]
        }
    }
});

// Determine the environment and set the appropriate URI
const getEnvironment = () => {
    const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
    return isDev ? "development" :
        window.location.href.includes("acceptance") ? "acceptance" :
        "production";
};

const ENVIRONMENTS = {
    development: "http://localhost:1337/graphql",
    acceptance: "https://acceptance.okcomply.com/graphql",
    production: "https://my.okcomply.com/graphql"
};

// Create the HTTP link
const httpLink = new HttpLink({
    uri: ENVIRONMENTS[getEnvironment()],
});

// Error handling link
const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
    }
    if (networkError) console.error(`[Network error]: ${networkError}`);
});

// Authentication link
const authLink = setContext((_, { headers }) => {
    const cookies = new Cookies();
    const token = cookies.get("id_token");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    };
});

// Combine links
const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

// Create Apollo Client
const client = new ApolloClient({
    link,
    cache: new InMemoryCache({ fragmentMatcher }),
    dataIdFromObject: (o) => o.id,
});

export default client;
