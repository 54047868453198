import client from "graphql/client";
import { loader } from "graphql.macro";
const fetchMFADataQuery = loader("graphql/queries/users/fetchMFADataQuery.graphql");

export function fetchMFAData(userId=null) {
    return () => {
        return client.query({
            query: fetchMFADataQuery,
            variables: { userId },
            fetchPolicy: "network-only"
        });
    };
}